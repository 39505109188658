<template>
    <!-- Portfolio -->
    <section class="mx-auto mt-10">
        <div class="flex-container flex flex-col items-center justify-start">
            <!-- Data Viz -->
            <div class="relative flex-item w-[300px] h-[200px] md:w-[600px] md:h-[400px] m-5 overflow-hidden">
                <router-link to="/datavisualization">
                <a class="absolute inset-0 z-10 bg-white text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
                    <p class="text-3xl">Data Visualization</p>
                </a>
                <img :data-src="require(`@/assets/img/hmap.png`)" alt="hmap" class="lazyload mx-auto"></router-link>
            </div>

            <!-- Digital Illu -->
            <div class="relative flex-item w-[300px] h-[200px] md:w-[600px] md:h-[400px] m-5 overflow-hidden">
                <router-link to="/digitalillustration">
                <a class="absolute inset-0 z-10 bg-white text-center flex flex-col items-center justify-around opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
                    <p class="text-3xl">Digital Illustration</p>
                </a>
                <img :data-src="require(`@/assets/img/Oriental-flowers-tile.jpg`)" alt="Oriental-flowers-til" class="lazyload mx-auto"></router-link>
            </div>

            <!-- Traditional Painting -->
            <div class="relative flex-item w-[300px] h-[200px] md:w-[600px] md:h-[400px] m-5 overflow-hidden">
                <router-link to="/traditionalpainting">
                <a class="absolute inset-0 z-10 bg-white text-center flex flex-col items-center justify-around opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
                    <p class="text-3xl">Painting and Photography</p>
                </a>
                 <img alt="gallery" class="lazyload mx-auto" :data-src="require(`@/assets/img/scaled/sailing-ocean-1.jpg`)"></router-link>
            </div>          

        </div>
    </section>
</template>

<script>


export default {
    name: "PortfolioComponent",


}
</script>

<style>

</style>