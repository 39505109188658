<template>
  <section>
    <p class="text-4xl p-5 my-5">Contact</p>
    <div class="container my-5 leading-8 text-lg p-5 ">
      <form ref="form" @submit.prevent="sendEmail" id="form">
        <div class="flex-container flex flex-col items-start mx-auto w-fit">  
          <div>
            <label>Name</label>
          </div>
          <div>
            <input type="text" name="user_name" class="bg-gray-200 md:w-[300px]" required>
          </div>
          <div>
            <label>Email</label>
          </div>
          <div>
            <input type="email" name="user_email" class="bg-gray-200 md:w-[300px]" required>
          </div>
          <div>
            <label>Message</label>
          </div>
          <div>
            <textarea name="message" class="bg-gray-200 h-[100px] md:w-[300px] px-2 py-1" required></textarea>
          </div>
          <div class="mx-auto">
            <div class="g-recaptcha" id="elementRecaptcha" :data-sitekey="`${captchaSiteKey}`"></div>
            <br>
            <input type="submit" value="Send"  class="bg-green-200 px-2 py-1 mt-5 rounded hover:scale-95">
          </div>      
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: "Email",
  methods: {
    sendEmail() {
      const form = document.getElementById("form")
      emailjs.sendForm(process.env.VUE_APP_EMAILJS_SERVICE, process.env.VUE_APP_EMAILJS_TEMPLATE, this.$refs.form, process.env.VUE_APP_EMAILJS_USER)
        .then((result) => {
            form.reset();
            grecaptcha.reset();
            alert('Message Sent!');
        }, (error) => {
            alert('FAILED! Please try again later.');
            console.log(error)
        });
    }
  },
  computed: {
    captchaSiteKey() {
      return process.env.VUE_APP_CAPTCHA
    }
  },
  created() {
    this.$nextTick(function() {
      grecaptcha.render('elementRecaptcha')
    })
  }
}
</script>

<style scoped>
div {
  padding: 5px;
}

</style>