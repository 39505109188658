<template>
    <IllustrationComp />
</template>

<script>
import IllustrationComp from '@/components/IllustrationComp.vue'

export default {
    components: { IllustrationComp },
    name: "IllustrationView"
}
</script>

<style>

</style>