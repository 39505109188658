<template>
  <section class="overflow-hidden ">
    <p class="text-4xl p-5 my-5">Traditional Painting</p>
  <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
    <div class="flex flex-wrap -m-1 md:-m-2">
      <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/bone.png`)">
        </div>
      </div>
      <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full " :data-src="require(`@/assets/img/scaled/sailing-ocean-1.jpg`)">
        </div>
      </div>
      <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/scaled/cherry-blossom.jpg`)">
        </div>
      </div>
      <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/scaled/dawn-in-city.jpg`)">
        </div>
      </div>
      <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/Lotus-water-lily.jpg`)">
        </div>
      </div>
      <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/scaled/rock-and-pine-1.jpg`)">
        </div>
      </div>
      <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/scaled/ship.jpg`)">
        </div>
      </div>
        <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/scaled/sunset-lake.jpg`)">
        </div>
      </div>
        <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/scaled/rose.jpeg`)">
        </div>
      </div>
        <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/scaled/leaf-1.jpeg`)">
        </div>
      </div>
        <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/scaled/leaf-2.jpeg`)">
        </div>
      </div>
        <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="lazyload block object-cover object-center w-full h-full "
            :data-src="require(`@/assets/img/scaled/leaf-3.jpeg`)">
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>


export default {
    name: "TraditionalPaintingComp"
}
</script>

<style>

</style>