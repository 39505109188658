<template>
<div class="container">
<div id="home-carousel" class="carousel slide relative w-[300px] h-[400px] md:w-[600px] md:h-[800px] mx-auto overflow-hidden" data-bs-ride="carousel">
  <div class="carousel-inner relative overflow-hidden">
    <div class="carousel-item active relative mx-auto">
      <img
        src="../assets/img/galaxy.jpeg"
        class="h-full w-full object-contain"
        alt="galaxy"
      />
    </div>
    <div class="carousel-item relative mx-auto">
      <img
        src="../assets/img/water-blue.jpeg"
        class="h-full w-full object-contain"
        alt="water-blue"
      />
    </div>
    <div class="carousel-item relative mx-auto">
      <img
        src="../assets/img/Lotus-water-lily.jpg"
        class="h-full w-full object-contain"
        alt="lotus-water-lily"
      />
    </div>
  </div>
  <button
    class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
    type="button"
    data-bs-target="#home-carousel"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
    type="button"
    data-bs-target="#home-carousel"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
</template>

<script>

export default {
  name: 'HomeComponent',
  props: {
  },
  components: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
