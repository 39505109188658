import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import 'tw-elements'
import 'lazysizes'
import 'vue-progressive-image/dist/style.css'
import vueProgressiveImage from 'vue-progressive-image'

createApp(App).use(store).use(router).use(vueProgressiveImage).mount('#app')
