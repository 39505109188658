<template>
  <Email />
</template>

<script>
import Email from '@/components/Email.vue'
export default {
    name: "ContactView",
    components: {
      Email
    }
}
</script>

<style>

</style>