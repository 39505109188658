<template>
    <section class="overflow-hidden">
        <p class="text-4xl p-5 my-5">Data Visualization</p>
        <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
            <video autoplay="true" muted="true" class="block object-cover object-center md:w-[600px] w-[300px] mx-auto w-fit m-5" controls>
                <source src="@/assets/img/ao3/bcr-rating.mp4" type="video/mp4">
                Your browser does not support this video.    
            </video>
            <div class="flex flex-wrap -m-1 md:-m-2">
                <ImgPost fileurl="ao3/boxplot.png" />
                <ImgPost fileurl="ao3/hmap.png" />
                <ImgPost fileurl="ao3/output_9_1.png" />
                <ImgPost fileurl="ao3/output_12_0.png" />
                <ImgPost fileurl="ao3/output_14_1.png" />
                <ImgPost fileurl="ao3/output_15_1.png" />
                <ImgPost fileurl="ao3/output_18_1.png" />
                <ImgPost fileurl="ao3/output_22_0.png" />
                <ImgPost fileurl="ao3/output_23_1.png" />
                <ImgPost fileurl="ao3/output_24_0.png" />
                <ImgPost fileurl="ao3/output_26_0.png" />
            </div>
            
        </div>
    </section>
</template>

<script>
import ImgPost from "@/components/ImgPost.vue"

export default {
    name: "DataVisualizationComp",
    components: {
        ImgPost
    }
}
</script>

<style>

</style>