<template>
<!-- Lazyload Images -->
    <div class="flex flex-wrap w-1/2">        
        <div class="w-full p-1 md:p-2">
            <img alt="gallery" class="lazyload block object-cover object-center w-full h-full " :data-src="require(`@/assets/img/${ fileurl }`)">
        </div>
    </div>
</template>

<script>
export default {
    name: "ImgPost",
    props: {
        fileurl: String,
    }
}
</script>

<style>

</style>