<template>
  <PortfolioComponent />

  
</template>

<script>
import PortfolioComponent from '@/components/PortfolioComponent.vue'

export default {
    name: "PortfolioView",
    components: {
      PortfolioComponent
    }
}
</script>

<style>

</style>