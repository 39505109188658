<template>
  <div class="about">
    <AboutComponent />
  </div>
</template>

<script>
import AboutComponent from '@/components/AboutComponent.vue'

export default {
  name: "AboutView",
  components: {
    AboutComponent
  }
}
</script>

<style>

</style>
