<template>
     <section class="overflow-hidden">
        <p class="text-4xl p-5 my-5">Digital Illustration</p>
        <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
            <div class="flex flex-wrap -m-1 md:-m-2">
                <ImgPost fileurl="illu/illu1.jpg" />
                <ImgPost fileurl="illu/illu2.jpg" />
                <ImgPost fileurl="illu/illu3.jpg" />
                <ImgPost fileurl="illu/illu4.jpg" />
            </div>
        </div>
     </section>
</template>

<script>
import ImgPost from "@/components/ImgPost.vue"

export default {
    name: "IllustrationComp",
    components: {
        ImgPost,
    }
}
</script>

<style>

</style>