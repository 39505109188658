<template>
    <DataVisualizationComp />
</template>

<script>
import DataVisualizationComp from '@/components/DataVisualizationComp.vue';
export default {
    name: "DataVisualizationView",
    components: { DataVisualizationComp }
}
</script>

<style>

</style>