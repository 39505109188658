<template>
  <div class="traditional-painting">
    <TraditionalPaintingComp />
  </div>
</template>

<script>
import TraditionalPaintingComp from "@/components/TraditionalPaintingComp.vue"

export default {
  name: "TraditionalPainting",
  components: {
    TraditionalPaintingComp
  }
}
</script>

<style>

</style>
