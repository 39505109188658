<template>
  <!-- About -->
  <section class="mx-auto">
    <div class="container">
        <!-- Profile -->
        <img src="../assets/img/profile.png" class="mx-auto w-[200px] p-5 mb-5" alt="profile">
        <span class="text-4xl p-5 my-5">About</span>
        <div class="md:text-start text-center my-1 mx-auto md:w-[600px] w-fit leading-8 text-lg p-5">
            <p>
            Hi there!<br>
            This site is maintained by Emily Kz.<br>
            Emily is a free-lance illustrator and full-time software developer. Most of her watercolour paintings are produced en plein air. Digital paintings are created in procreate.<br>
            If she is not painting, she is creating data visualization.
            </p>
        </div>

        <!-- Exhibition -->
        <div class="my-5">
            <span class="text-4xl p-5 my-1">Exhibition & Collection</span>
            <div class="mx-auto text-start md:w-[600px] w-fit leading-10 text-lg p-5 my-1">
            Becoming Montreal: Contemporary Views of Duncan, Montreal, 2023<br>
            Wonderland - The Sketchbook Project, Vol 14, New York City 2019<br>
            Expo Canal de Lachine, Montreal, 2019<br>
            À L’OEUVRE, Montreal, 2018<br>
            Montréal Monument, Montreal, 2017<br>
            Dessine-moi une montagne, Montreal, 2016<br>
            </div>
        </div>

    </div>
  </section>
</template>

<script>
export default {
    name: 'AboutComponent'

}
</script>

<style>

</style>